import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import ProjectCard from '../components/ProjectCard'
import Layout from '../components/Layout'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map((post, key) => (
      <ProjectCard
        key={key} 
        title={post.node.frontmatter.title} 
        metaDesc={post.node.frontmatter.metaDesc}
        metaImg={post.node.frontmatter.metaImg.childImageSharp.fluid}
        hoverImg={post.node.frontmatter.hoverImg.childImageSharp.fluid}
        slug={post.node.fields.slug}
      />
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount

    return (
      <Layout>
        <section className="section">
          <Helmet title={`${tag} | ${title}`} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <Link to="/">&uarr; View All Projects</Link>
                <br/>
                <br/>
                <Link to="/tags/">&larr; View All Capabilities</Link>
                <h3 className="title is-size-4 is-bold-light">{`${tag}`}</h3>
                <h4>{`${totalCount} Projects`}</h4>
                <br/>
                <div className="columns is-multiline">
                  {postLinks}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            templateKey
            metaDesc
            metaImg {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            hoverImg {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }            
          }
        }
      }
    }
  }
`
